<template>
  <ClientOnly>
    <div class="mb-5">
      <div class="relative">
        <Carousel ref="carousel" :autoplay="3000" :wrap-around="true">
          <Slide v-for="item in items" :key="item.permalink!">
            <NuxtLink
              :to="createArticleLink(item.permalink!)"
              class="block h-[260px] relative w-full"
            >
              <span
                class="bg-[#ff5a76] absolute text-white z-10 top-2 left-2 py-1.5 px-8 leading-none rounded-md"
                >やせるニュース</span
              >
              <img
                v-if="item.image_file"
                :src="createArticleImagePath(item.image_file)"
                class="object-cover w-full h-full relative"
              />
              <AtomsPlaceholderImage
                v-else
                class="object-cover w-full h-full relative"
              />
              <span
                class="absolute bottom-0 left-0 w-full text-left bg-white bg-opacity-70 px-4 py-2 leading-none"
                >{{ item.title }}</span
              >
            </NuxtLink>
          </Slide>
        </Carousel>

        <button
          class="absolute top-1/2 left-3 -translate-y-1/2 text-white"
          @click="prev"
        >
          <ChevronLeftCircleIcon :size="22" />
        </button>
        <button
          class="absolute top-1/2 right-3 -translate-y-1/2 text-white"
          @click="next"
        >
          <ChevronRightCircleIcon :size="22" />
        </button>
      </div>

      <div class="flex justify-center gap-6 mt-4">
        <button
          v-for="(item, i) in items"
          :key="item.permalink!"
          @click="slideTo(i)"
        >
          <CircleIcon
            :class="{
              'opacity-50': i !== currentSlide,
            }"
            :size="6"
          />
        </button>
      </div>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
import ChevronLeftCircleIcon from 'vue-material-design-icons/ChevronLeftCircle.vue';
import ChevronRightCircleIcon from 'vue-material-design-icons/ChevronRightCircle.vue';
import CircleIcon from 'vue-material-design-icons/Circle.vue';
import type { GetArticlesQuery } from '@shared/gql/graphql';
import { createArticleImagePath } from '@shared/utils/createArticleImagePath';

defineProps({
  items: {
    type: Array as PropType<GetArticlesQuery['article']>,
    required: true,
  },
});

const carousel = ref<{
  next: () => void;
  prev: () => void;
  slideTo: (i: number) => void;
  data: {
    currentSlide: Ref<number>;
  };
}>();

function next() {
  carousel.value?.next();
}

function prev() {
  carousel.value?.prev();
}

function slideTo(i: number) {
  carousel.value?.slideTo(i);
}

const currentSlide = computed(() => {
  return carousel.value?.data?.currentSlide.value ?? 0;
});
</script>
